import React from 'react'

const LifeTimeLogo = () => {
  return (
    <g transform="translate(19.52 19.5)" fill="none" fillRule="evenodd">
      <circle fill="#087FB9" cx="40.573" cy="40.588" r="40.438" />
      <path
        d="M60.813 33.17c4.097 4.082 4.097 10.698-.015 14.75-2.32 2.32-5.806 3.004-10.384-1.558l-8.835-8.806 4.417-4.387c4.097-4.07 10.735-4.07 14.816 0zm-25.75 0L49.37 47.425c3.593 3.582 5.935 4.16 7.903 4.038L46.47 62.232c-1.953 1.946-3.96 2.844-5.928 2.844-2.075 0-4.105-1.004-5.95-2.844L20.247 47.936c-4.082-4.084-4.082-10.684 0-14.767 2.037-2.032 4.737-3.05 7.408-3.05 2.67 0 5.363 1.01 7.408 3.05zm22.882-9.423c0 2.494-2.022 4.525-4.54 4.525-2.518 0-4.54-2.038-4.54-4.525 0-2.51 2.022-4.524 4.54-4.524 2.495 0 4.54 2.015 4.54 4.524zm-25.734 0c0 2.494-2.02 4.525-4.54 4.525-2.5 0-4.54-2.016-4.54-4.525 0-2.51 2.023-4.524 4.54-4.524 2.496 0 4.54 2.015 4.54 4.524z"
        fill="#FFF"
      />
    </g>
  )
}

export default LifeTimeLogo
