import React from 'react'

const BamLogo = () => {
  return (
    <g fill="none" fillRule="evenodd" transform="translate(19 19)">
      <path
        d="m40.9493906 81.8987812c22.6157709 0 40.9493906-18.3336838 40.9493906-40.9493906 0-22.6157708-18.3336197-40.9493906-40.9493906-40.9493906-22.6157067 0-40.9493906 18.3336198-40.9493906 40.9493906 0 22.6157068 18.3336839 40.9493906 40.9493906 40.9493906z"
        fill="#000"
      />
      <g fill="#fff">
        <path d="m16.7855922 50.5598333h3.5316375v-.5473073c.9699703.7213438 1.7159995.9701198 2.7606453.9701198 3.0839687 0 5.5710885-2.6613698 5.5710885-5.9441458 0-3.2829896-2.4871198-5.919375-5.5710885-5.919375-1.0446458 0-1.7657974.248776-2.7606453.9701198v-6.1183958h-3.5316375zm5.8944547-8.2572291c1.4424739 0 2.4123802 1.1191718 2.4123802 2.7358958 0 1.6414948-.9699063 2.7606667-2.4123802 2.7606667-1.4425808 0-2.3876735-1.0944011-2.3876735-2.7606667 0-1.6664792.9450927-2.7358958 2.3876735-2.7358958z" />
        <path d="m41.4574062 50.5598333v-11.0178958h-3.5565364v.5473073c-.9451354-.6965729-1.7160208-.9701198-2.7606667-.9701198-3.0839687 0-5.5710885 2.6363854-5.5710885 5.919375 0 3.282776 2.4871198 5.9441458 5.5710885 5.9441458 1.0446459 0 1.8155313-.2737604 2.7606667-.9701198v.5473073zm-5.8446354-8.2572291c1.3679479 0 2.3378542 1.1191718 2.3378542 2.7358958 0 1.6662656-.9699063 2.7606667-2.3876094 2.7606667-1.4674583 0-2.4373646-1.1191719-2.4373646-2.7856511 0-1.6165104.9948907-2.7109114 2.4871198-2.7109114zm2.4871198-8.4808073c-1.0694166 0-1.9647969.8454114-1.9647969 1.8652864 0 1.0941875.845625 1.9398125 1.9647969 1.9398125 1.0694167 0 1.9398125-.845625 1.9398125-1.9150416 0-1.0446459-.8703958-1.8900573-1.9398125-1.8900573zm-4.7006927 0c-1.0694167 0-1.9647969.8454114-1.9647969 1.8652864 0 1.0941875.8706094 1.9398125 1.9647969 1.9398125 1.0694167 0 1.9400261-.845625 1.9400261-1.9150416 0-1.0446459-.8706094-1.8900573-1.9400261-1.8900573z" />
        <path d="m47.3020417 39.5419375h-3.5067813v11.0178958h3.5315521v-5.4965625c0-1.1191718.0747396-1.7160208.2985312-2.1140625.2237917-.3978281.7213438-.6466041 1.2932084-.6466041 1.019875 0 1.3431771.5968489 1.3431771 2.536875v5.7203541h3.531552v-5.4965625c0-1.1439427.0747396-1.7160208.2985313-2.1140625.2237917-.3978281.7211302-.6466041 1.2932083-.6466041 1.0196615 0 1.3429636.5968489 1.3429636 2.536875v5.7203541h3.5317656v-5.8943906c0-1.4922292-.1990208-2.4125937-.6715885-3.2829896-.8206407-1.4424739-2.288099-2.2633281-4.0788594-2.2633281-1.2184688 0-2.0890781.3233021-3.1087396 1.1191719-.9451354-.7958698-1.7410052-1.1191719-2.7606667-1.1191719-.8703958 0-1.4674583.2237917-2.3378541.8706094z" />
        <path d="m63.716776 45.9834219h3.3077605l.2985312-12.0125729h-3.9048229zm1.6662657.8706093c-1.1689271 0-2.113849.9201511-2.113849 2.0145521 0 1.1439427.920151 2.0643073 2.113849 2.0643073 1.1441562 0 2.0892916-.9203646 2.0892916-2.0395364 0-1.1439427-.9451354-2.039323-2.0892916-2.039323z" />
      </g>
    </g>
  )
}

export default BamLogo
